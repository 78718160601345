body>iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
    display: none !important;
}


html {

    @apply bg-dark-2;

    body {
        @apply text-gray-300;

        *,
        ::before,
        ::after {
            @apply border-dark-3;
        }
    }

    body {
        -webkit-font-smoothing: antialiased;
        @apply overflow-x-hidden py-0 px-0 font-roboto text-sm text-gray-800;

        @include media-breakpoint-down(sm) {
            @apply px-0;
        }
    }
}

.btn-primary-2 {
    background: #0042C6;
    color: #fff;
    border: 0.841936px solid #0042C6;
    border-radius: 6.73548px;
    padding: 10.41936px 14.3129px;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;

}

.btn-secondary {
    background: transparent;
    color: #0042C6;
    border: 0.841936px solid #0042C6;
    border-radius: 6.73548px;
    padding: 10.41936px 14.3129px;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;

}

.search-primary {
    background: #FFFFFF;
    border: 0.559199px solid #D7D7D7;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 6.7px;
    padding: 8.41936px 14.3129px;
    font-size: 12px;
}

.tb-report {

    tr:nth-child(odd) {
        background-color: #f4f4f4;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #ECECEC;
    }

    tr,
    th {
        padding: 1.2rem 1.25rem;

    }


    th {
        font-weight: 500;
    }
}

.bg-white-important {
    background: #fff !important;
}

.status-accepted,
.status-pending,
.status-error,
.status-failed,
.status-cancelled {

    color: #000;
    font-size: 14px;
}


.status-pending {
    background: #FFF5CA;

}

.status-success{
    background: #6BA84E;
}

.status-error, .status-failed {
    background: #CD0000;
}

.status-cancelled {
    background: #FFEFE2;
}

.status-accepted {
    background: #A4C2F4;
}

.status-reversed {
    background: #E79138;
}

.status-dispatched {
    background: #B5A8D5;
}

.status-created {
    background: #CCCBCC;
}

#custom-form-select {

    background-size: 18px;
    background-position: center right 0.6rem;
    width: 100%;
    appearance: none;
    border-color: #DEDEDE;
    border-radius: 0.375rem;
    border-width: 1px;
    background-repeat: no-repeat;
    padding: 0.5rem 2rem 0.5rem 0.75rem;
    font-size: 12px !important;
}

.mt-13-important {
    margin-top: 13vh !important;
}


progress {
    -webkit-appearance: none;
    width: 100% !important;
}

::-webkit-progress-bar {
    background-color: #D9D9D9;
    height: 2px;
    width: 100% !important;


}

::-webkit-progress-value {
    background-color: #0042C6;

}


.amount-to-send {

    &::placeholder {
        color: #0042C6;
        font-weight: 600;
    }
}
// @layer components {
//     .login {
//         @apply relative bg-white dark: bg-dark-1;

//         @include media-breakpoint-down(xl) {
//             background: linear-gradient(to bottom, #1c3faa, #2b5100);
//             background-repeat: no-repeat;
//             background-attachment: fixed;
//         }

//         &:before {
//             content: "";
//             margin-left: -48%;
//             //background-image: url(../images/bg-login-page.svg);
//             background-repeat: no-repeat;
//             background-size: auto 100%;
//             background-position: right;
//             @apply w-full h-full absolute top-0 left-0;

//             @include media-breakpoint-down(xl) {
//                 @apply hidden;
//             }
//         }

//         .login__input {
//             min-width: 350px;
//             box-shadow: 0px 3px 5px #00000007;

//             @include media-breakpoint-down(xl) {
//                 @apply min-w-full;
//             }
//         }
//     }

//     .dark {
//         .login {
//             &:before {
//                 //background-image: url(../images/bg-login-page-dark.svg);
//             }

//             @include media-breakpoint-down(xl) {
//                 background: #232a3b;
//             }
//         }
//     }
// }

.bg-auth-img {

    background-image: url("../images/auth-cover.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 70%;
    min-height: 100vh;
    // padding: 2rem 3rem;
    position: relative;
    width: 100%;
}


.auth-bg-cover-theme {
    background: linear-gradient(180deg, #0251FD 0%, #0036AE 100%);
}
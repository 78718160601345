@layer components {
    .notification {
        @apply relative text-theme-7;
        &.notification--light {
            @apply text-theme-30;
        }
        .notification__icon {
            @apply w-5 h-5;
        }
        &.notification--bullet:before {
            content: "";
            width: 8px;
            height: 8px;
            @apply rounded-full absolute right-0 bg-theme-6;
        }
    }
}

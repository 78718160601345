@layer components {
    .main {
        background-repeat: no-repeat;
        background-attachment: fixed;
        // @apply mt-[13vh];
    }

    .icon {
        height: 24px;
        width: 24px;
    }

    .wallet-icon {
        height: 40px;
        width: 40px;
    }

    .image-preview:hover {
        cursor: pointer;
    }

    .timeline {
        list-style: none;
        padding-left: 0;

        .rtl & {
            padding-right: 0;
        }

        .timeline-item {
            border-left: 1px solid #dee2e6 !important;
            color: #6c757d;
            padding-left: 20px !important;
            padding-bottom: 20px !important;
            position: relative;

            &:before {
                content: "";
                height: 10px;
                width: 10px;
                border-radius: 10px;
                position: absolute;
                top: 0px;
                left: -5px;
            }

            &:nth-child(4n + 1) {
                &:before {
                    background: #1C3FAA;
                }
            }

            &:nth-child(4n + 2) {
                &:before {
                    background: #19d895;
                }
            }

            &:nth-child(4n + 3) {
                &:before {
                    background: #ffaf00;
                }
            }

            &:nth-child(4n + 4) {
                &:before {
                    background: #8862e0;
                }
            }

            &:last-child {
                border-left: none !important;
                padding-bottom: 0 !important;
            }

            .timeline-content {
                margin-bottom: 0;
                line-height: 10px;

            }

            .event-time {
                margin-bottom: 0;
            }
        }
    }

    .text-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        width: 43px;
        min-width: 43px;
        height: 43px;
        border-radius: 50% !important;
    }

    .confirmationContent {
        vertical-align: middle;
        text-align: center;
        align-items: center;
        justify-content: center;
        align-self: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 480px;
    }

    .confirmationCheck>svg {
        font-size: 70px;
    }

    .z-100 {
        z-index: 100;
    }


    .mt-0 {
        margin-top: 0 !important;
    }

    .w-fit {
        width: fit-content;
    }

    .react-datepicker-wrapper {
        line-height: 1.25;
        color: rgba(74, 85, 104, var(--tw-text-opacity));
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 2rem;
        padding-left: 1rem;
        --tw-bg-opacity: 1;
        background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
        --tw-border-opacity: 1;
        border-color: rgba(237, 242, 247, var(--tw-border-opacity));
        border-width: 1px;
    }

    .react-tooltip {
        overflow: visible;
        z-index: 999;
    }


    .PhoneInput {
        border: 1px solid #D4D4D4;
        position: relative;
        border-radius: 5px;
        padding: 11px 13px;
        background: white;
        z-index: 10;
    }



    .PhoneInputInput,
    .PhoneInputCountrySelect>option,
    .PhoneInputInput {
        touch-action: manipulation !important;
        font-size: 1.125rem !important;
        line-height: 1.75rem !important;
        --tw-bg-opacity: 1 !important;
        // background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
        // border-radius: 0.125rem;
        --tw-border-opacity: 1;
        // border-color: rgba(49, 58, 85, var(--tw-border-opacity));
        // color: white;
        outline: none;
    }
}

.label-text {
    color: #6C6F85;
}

.msisdn-error {
    border: 1px solid #F9105F !important;
}

.msisdn-error-text {
    border: 1px solid #F9105F !important;
    margin-top: -6px !important;
    padding-top: 8px !important;
    z-index: -1;
    position: relative;
}


.input-primary {
    border: 1px solid #D4D4D4;
    position: relative;
    border-radius: 5px;
    padding: 11px 13px;
    background: white;
    z-index: 10;
}


.icon-color {
    color: #9095A3;
}

.text-link {
    color: #0251FD;
}

.primary-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: white;
    background: #0251FD;
    border-radius: 5px;
    padding-top: 14px;
    padding-bottom: 14px;
    transition: all .3s linear;
    padding-right: 16px;
    padding-left: 16px;



    &:hover {
        background: #0146db;
    }

    &:disabled {
        cursor: default;
    }

    // width: 100%;
}

.primary-btn-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: white;
    background: #0042C6;
    border-radius: 5px;
    padding-top: 14px;
    padding-bottom: 14px;
    transition: all .3s linear;
    padding-right: 16px;
    padding-left: 16px;



    &:hover {
        background: #0146db;
    }

    &:disabled {
        cursor: default;
        background: #dee2e6;
    }

    // width: 100%;
}


.otp-style {
    width: 46px !important;
    height: 46px !important;
    // padding: 11px 13px;
    // height: 2.5rem; 
    margin: 0 .3rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #D4D4D4;
}

.error-otp-style {
    width: 46px !important;
    height: 46px !important;
    // padding: 11px 13px;
    // height: 2.5rem;
    margin: 0 .3rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #F9105F;
    color: #F9105F !important;
}



.modal-small {
    height: auto;
    width: 30vw;
    margin: auto;

    @media (max-width: 767px) {
        width: 90vw;
    }
}

.modal-medium {
    height: auto;
    width: 35vw;
    margin: auto;

    @media (max-width: 767px) {
        width: 90vw;
    }
}

.modal-lg {
    height: auto;
    width: 50vw;
    margin: auto;

    @media (max-width: 767px) {
        width: 90vw;
    }
}

#custom-react-select-id,
.custom-react-select-class {
    background-color: white;
    border: none !important;
    outline: none !important;
    // z-index:  !important;

    span {
        z-index: 9 !important;
    }

    .react-select__indicators {
        display: none !important;
    }

    .react-select__control {
        min-height: 0 !important;
    }

    .react-select__value-container {
        color: #000000 !important;
        font-weight: 500 !important;
        padding: 0 !important;
        font-size: 14px !important;
        line-height: 15px;
    }

    input,
    .react-select__control {
        border: none !important;
        outline: none !important;
        background-color: transparent;
        box-shadow: none !important;
    }
}


.ant-pagination-item-active {
    border-color: #0042C6 !important;
    background: #0042C6 !important;
    color: #fff !important;

    a {
        color: #fff !important;
    }
}


.ant-pagination-item {
    background: #FFFFFF;
    border: 1px solid #999999 !important;

}

.ant-pagination-prev,
.ant-pagination-next {
    button {
        color: #999999 !important;
    }
}

.ant-steps-item-process {
    .ant-steps-item-icon {
        background-color: #0042C6 !important;
        border-color: #0042C6 !important;
    }

}



.PhoneInput-2 {
    border: 1px solid #D4D4D4;
    position: relative;
    border-radius: 5px;
    padding: 5px 13px !important;
    background: white;
    z-index: 10;
}